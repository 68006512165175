@import "variables";
@import "mixins";

.pathway-row {
    margin-left: 1.5rem;
    margin-right: 1rem;
    .ui.segment.pathway-segment {
        background-color: $dr-white;
        &.selected {
            border-color: $verdigris;
        }
        .ui.one.column.grid {
            margin: 4rem;
            .ui.button.filled-button {
                width: 100%;
            }
            .row {
                padding-bottom: 0;
                &.header {
                    padding-top: 0;
                }
                &.description {
                    padding-top: 0px;
                    padding-bottom: 2rem;
                }
            }
        }
    }
}

.qr-code {
    margin: 0 auto;
    display: block;

    @media screen and (max-width: 345px) {
        height: 250px !important;
        width: 250px !important;
    }
}

.patient-search {
    &__buttons-wrapper {
        display: flex;
        justify-content: flex-end;

        button {
            width: spacing(15);
        }
    }

    &__error {
        color: $error-color;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: spacing(1);

        .empty-button {
            display: flex;
            align-items: center;
        }
    }

    &__error-wrapper {
        margin-top: spacing(1);
        width: fit-content;
    }
}

.advanced-search {
    &__text {
        text-decoration: underline;
        text-underline-offset: spacing(0.5);
    }

    &__button {
        background: transparent;
        border: none;
    }

    &__form-wrapper {
        margin-top: spacing(2.5);
    }
}

.patient-search-results {
    &__table.ui.sortable.table {
        thead th {
            text-transform: uppercase;
            background-color: $table-header;

            .sorted {
                background-color: $table-header;
            }
        }

        &.message {
            padding: 0;

            thead th {
                height: spacing(7);
            }

            td {
                height: spacing(15);
                text-align: center;
                font-weight: bold;
            }
        }
    }

    &__total-patients-text {
        span {
            font-weight: bold;
        }

        span:first-of-type {
            background-color: $astral;
            padding: 2px 7px;
            color: $white;

            border-radius: 4px;
        }

        span:last-of-type {
            margin-left: 10px;
        }
    }

    &__footer {
        width: 100%;
        text-transform: uppercase;
        color: $gray;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        margin-bottom: spacing(2);

        .pages-info {
            display: flex;
            align-items: center;
        }

        .ui.menu.pagination {
            background-color: transparent;
            border: none;
            box-shadow: none;

            .item {
                color: $gray;
                font-weight: bold;
                text-transform: uppercase;

                &:before {
                    width: 0;
                }

                &.active {
                    background-color: none;
                    color: $black;
                }
            }
        }
    }

    &__item-label {
        padding: spacing(1) spacing(2);
        border-radius: spacing(1);
        font-weight: bold;
        width: fit-content;

        &.deceased {
            background-color: $bright-gray;
            color: $white;
        }

        &.merged-record {
            background-color: $yellow;
            color: $shark;
        }
    }
}

.patient-search-expanded-view {
    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .merged-record {
            margin-left: spacing(3);
        }
    }

    &__wrapper {
        font-weight: bold;

        .ui.dropdown {
            border-radius: spacing(1);
            border: 1px solid $weathered-stone;
            min-height: spacing(5);
        }
    }

    &__title {
        color: $mid-grey;

        text-transform: uppercase;
        margin-bottom: spacing(1);
    }

    &__content {
        border: 1px solid $weathered-stone;
        padding: spacing(1);
        border-radius: spacing(1);
        color: $black;
        min-height: spacing(5);
        display: flex;
        align-items: center;

        &.ui.selection.dropdown {
            min-width: 100%;
        }
    }

    &__fields.ui.grid {
        margin-bottom: spacing(2);
    }

    &__buttons-wrapper {
        margin-top: spacing(10);
        display: flex;
        justify-content: space-between;
    }

    &__error-wrapper {
        margin-top: spacing(1);
        width: fit-content;
        margin-bottom: spacing(-8);
    }

    &__select-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__select {
        .ui.dropdown .menu > .item {
            cursor: auto;

            &:hover,
            &.selected {
                background: none;
            }
        }
    }
}

.integration-patient-created-modal {
    &__wrapper {
        padding: spacing(7) spacing(10);
        background-color: $dr-white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
        color: $black;
        border-radius: spacing(1);
    }
}

.patient-search-exception-modal {
    &__wrapper {
        position: relative;
        background-color: $dr-white;
        border-radius: spacing(1);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: spacing(4) spacing(8);
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
    }

    &__text-wrapper {
        margin-top: spacing(1);
        text-align: center;
    }

    &__title {
        font-weight: bold;
        display: inline-flex;
        margin-top: spacing(1);
        white-space: pre;
    }

    &__close-button {
        position: absolute;
        right: spacing(1);
        top: spacing(1);
        background: none;
        border: none;
        cursor: pointer;
    }

    &__text {
        text-align: justify;
        margin-top: spacing(1);
    }
}

.manual-case-creation-modal {
    &__wrapper {
        padding: spacing(3);

        p {
            text-align: center;
            font-weight: bold;
        }
    }

    &__buttons {
        margin-top: spacing(3);
        display: flex;
        justify-content: space-between;
    }
}

.patient-details__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.translator-details {
    background-color: $info-background-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    font-weight: 600;
    color: $info-text-color;
    border-radius: 5px;
}

.translator-details-info-icon {
    flex-shrink: 0;
    margin: 5px;
}

.translator-details-info-link {
    color: $cornflower-blue;
    text-decoration: none;
}

.translator-details-content {
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.translator-details-actions {
    flex-shrink: 0;
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 4px;
}

.translator-details-right-action {
    margin-left: 30px;
}

.translator-details-action-icon-separator {
    margin-left: 5px;
}

img.translator-details-edit-icon {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.translator-details-edit-title {
    text-align: center;
}

.translator-details-edit {
    height: 400px;
    overflow: auto;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
}

img.remove-translator-details-confirmation-icon {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.remove-translator-details-confirmation-title {
    text-align: center;
}

.chaperone-details {
    background-color: $info-background-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    font-weight: 600;
    color: $info-text-color;
    margin-top: 10px;
    border-radius: 5px;
}

.chaperone-details-info-icon {
    flex-shrink: 0;
    margin: 5px;
}

.chaperone-details-info-link {
    color: $cornflower-blue;
    text-decoration: none;
}

.chaperone-details-content {
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.chaperone-details-actions {
    flex-shrink: 0;
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 4px;
}

.chaperone-details-right-action {
    margin-left: 30px;
}

.chaperone-details-action-icon-separator {
    margin-left: 5px;
}

img.chaperone-details-edit-icon {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.chaperone-details-edit-title {
    text-align: center;
}

.chaperone-details-edit {
    height: 150px;
    overflow: auto;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
}

img.remove-chaperone-details-confirmation-icon {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.remove-chaperone-details-confirmation-title {
    text-align: center;
}
