@import "mixins";
@import "variables";

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.case-review {
    background-color: #fffff8;
}

.symbols-counter {
    text-align: right;
    margin-top: spacing(-1);
}

.review-text {
    font-weight: 600;
}

.case-management-select {
    margin-bottom: spacing(2.5);
}

.ui.grid.case-status,
.case-status,
.case-notes {
    padding-top: spacing(0.5);
    .row {
        padding: 2px;

        p {
            margin-bottom: 5px;
            margin-left: 5px;
        }

        .ui.segment {
            background: #e5e5e5;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.clinical-notes {
    border: solid 1px #e0e0e0;
    margin-top: spacing(1);

    h4 {
        height: spacing(5);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colour-secondary;

        i.bordered.icon {
            border-radius: 50%;
            border: solid 1px $black;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    p {
        text-align: "center";
    }

    .notes-container {
        padding: 0 spacing(7.25) spacing(3.125);

        .note {
            span {
                width: 100%;
            }
        }

        .no-notes {
            text-align: center;
        }

        .MuiFormControl-root {
            width: 100%;
            border: solid 1px #77b2ce;
            border-radius: 5px;
            margin-top: spacing(4.25);
            padding: spacing(1.75);
            .MuiInput-underline:before {
                border-bottom: unset;
            }
        }

        .edit-note-input-info {
            display: flex;
            justify-content: space-between;
            padding-top: spacing(1);

            .character-count {
                flex-grow: 1;
                text-align: right;
            }
        }

        .error-message {
            color: $guardsman-red;
            font-size: 16px;
            font-weight: 400;
            margin: spacing(1) 0 0 spacing(0.5);

            .MuiButtonBase-root {
                .MuiButton-label {
                    color: $guardsman-red;
                    font-size: 16px;
                    font-weight: 400;
                    text-decoration: underline;
                    text-transform: none;
                }
            }

            .MuiButtonBase-root:hover {
                background-color: unset;
            }
        }

        .add-additional-note {
            display: flex;
            justify-content: flex-end;
        }

        .note-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .actual-note-wrapper {
                display: flex;
                align-items: center;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
                max-width: 100%;

                p {
                    width: 100%;
                }
            }

            notes-buttons {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
            }

            button {
                margin: 0 spacing(0.5);

                .MuiButton-startIcon {
                    margin-right: spacing(0.5);
                }
            }
        }

        .icon-spinning {
            animation: spin 2s linear infinite;
        }
    }
}

.submit-for-assessment-container {
    display: flex;
    justify-content: flex-end;
    padding: spacing(7.255) spacing(7.25) spacing(6.25) 0;
}

.ui.segment.additional-information {
    word-break: break-all;
    max-width: spacing(50);
}

.ui.table .location-cell {
    word-break: break-word;
}

.case-summary-no-lesions {
    padding-bottom: spacing(1);
    padding-top: spacing(2.5);

    h1: {
        font-size: 16;
        margin-bottom: 0px;
    }
}

.ui.grid.history-question {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;

    .column {
        margin-top: 5px;
        padding-left: 0px;
    }

    .row.question {
        padding-top: spacing(2.5);
        padding-bottom: 0px;
    }

    .row {
        padding: 0px;
    }

    .answer {
        margin: 0 spacing(0.5) spacing(0.5) 0;
        white-space: pre-wrap;

        &.selected-answer {
            background: $isle-royale;
        }

        &.not-selected-answer {
            background: #e5e5e5;
        }
    }

    .selected-answer {
        background: $isle-royale;
    }

    .not-selected-answer {
        background: #e5e5e5;
    }

    &.remote-model-summary-question {
        margin: spacing(1) 0;

        & .ui.segment.selected-answer {
            background: none;
            box-shadow: none;
            border: none;
        }

        b {
            font-weight: 400;
        }

        .row.question {
            padding: 0;
        }
    }
}

.ui.segment.answer {
    max-width: spacing(50);
    margin: 0 spacing(0.5) spacing(0.5) 0;
    word-break: break-word;

    &.selected-answer {
        background: $isle-royale;
    }

    &.not-selected-answer {
        background: #e5e5e5;

        &.action {
            cursor: pointer;
        }
    }
}

.tab-div {
    padding-left: spacing(2);

    @include mobile {
        padding-left: 0;
    }
}

.ui.header.without-bottom-margin {
    margin-bottom: 2px;
}

.subtitle-gray-text {
    color: gray;
    margin: 0px;
    white-space: pre-wrap;
    word-break: break-all;
}

.ui.divider.title-divider {
    margin-top: 2px;
}

.ui.header.lesion-top-padding {
    padding-top: spacing(2.5);
}

p.assessed-by-text {
    margin-top: spacing(2);
}

.banner-padding {
    padding-top: spacing(1);
}

.section-separate {
    padding-top: spacing(2.5);
}

.ui.header.capitalize {
    text-transform: capitalize;
}

.section-case-header {
    padding-top: spacing(5);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .assessed-by-text {
        padding: spacing(1) 0;
    }

    button {
        height: 37px;
        line-height: 14px !important;
    }
}

.section-case-sas-link,
.section-case-add-admin-notes {
    &__container {
        padding: spacing(2) 0 0;

        h3 {
            margin: 0;
        }
    }

    &__actions {
        display: flex;
        align-items: center;

        a {
            display: block;
            max-width: 40vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: underline;
            font-weight: bold;
            padding-right: 1em;
        }

        .ui.input > input,
        .ui.input > textarea {
            margin-right: 1em;
        }
    }

    &__case-sas-wrapper {
        display: flex;
        align-items: center;
    }

    &__subheader {
        padding: spacing(1) 0px;
    }

    &__form {
        width: 100%;

        .field {
            margin-right: spacing(1) !important;
            width: 50%;
        }

        display: flex;
        align-items: center;
    }

    &__message {
        margin: spacing(1) 0px;
        width: 50%;
    }
}

.report-segment {
    .grid .row {
        padding: 5px;
    }

    .ui.teal.button.filled-button {
        margin-bottom: 0px;
    }
}

.case-img-reassessment {
    .info-subtitle {
        margin-bottom: 2rem;
    }

    .ui.grid > .row > .column.reasons-selection-wrapper {
        display: flex;
        flex-direction: column;

        & > * {
            margin-bottom: 0.5rem;

            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }
}

.ui .column.pdf-name {
    margin-top: 30px;
    margin-left: spacing(-2.5);
    padding-top: spacing(1);
}

.pdf-icon {
    height: 22px;
    margin-top: spacing(1);
}

.ui.button.image-preview {
    background-color: $white;
    margin-top: spacing(1);
    padding: 0px;

    &.spaced-image {
        &:not(.retake-image) {
            margin-top: 30px;
        }
    }

    &.retake-image {
        &:before {
            content: "";
            display: block;
            height: spacing(2.5);
            background-color: $light-red;
            border-radius: 5px 5px 0 0;
        }
    }
}

.image-preview {
    &.retake-images-warning {
        width: calc(56.25% - 15px); // images length x3 - margin
    }
}

.ui.segment.note {
    background: #e5e5e5;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiButtonBase-root {
        background-color: unset;
        color: #707171;
    }
}

.image-type,
.reassess-img-question {
    font-size: 18px;
    margin-bottom: 0;
}

.pathway-consent-label {
    margin-bottom: 0;
}

.ui.table tr td.review-free-text-cell {
    border-top: none;
    padding-top: 0px;
    word-break: break-word;
}

.pathway-consent-logo {
    max-width: 200px;
    height: 100px;
}

.ui.grid .wide.column.pathway-consemnt-logo-wrapper {
    display: flex;
    justify-content: flex-end;
}

.case-review-wrapper {
    margin-bottom: spacing(2.5);
    margin-top: 30px;

    &__header-wrapper {
        position: relative;
    }

    &__tooltip-wrapper {
        &--right-align {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(50%);

            @include mobile {
                position: relative;
                transform: translateY(0%);
            }
        }
    }
}

.ui.grid.case-description-bottom-panel {
    margin-top: spacing(1);
}

.image-capture-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding: calc(2rem - 0.14286em) 0 1rem;

    h1,
    .ui.button.filled-button {
        margin: 0;
    }
}

.dropzone {
    position: relative;
    min-height: 300px;
    width: 400px;
    margin: 0 auto 30px;
    padding: spacing(2) spacing(2) spacing(3);
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_hidden {
        overflow: hidden;
        height: 0;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.upload-image-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 1rem;
    margin: 0 auto;
    width: spacing(50);

    .button:only-child {
        margin: 0 auto;
    }
}

.case-error {
    color: $error-color;
}

.case-buttons {
    padding-top: spacing(1);
}

.btn-copy-email {
    appearance: none;
    margin: 0;
    padding: 5px;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;

    &.disabled {
        cursor: auto;
        color: $porpoise;
    }
}

.ui.message {
    &.warning {
        padding-top: spacing(1);
        background-color: $white;
        border-left: 5px solid $yellow;
        border-color: $yellow;
        box-shadow: 0 0 0 1px $yellow inset, 0 0 0 0 transparent;
        color: $black;
    }
}

.case-note {
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.buttons-row-wrapper.case-callback {
    justify-content: space-between;
    margin-top: spacing(2.5);
    margin-bottom: spacing(2);

    .ui.button {
        width: auto;
    }
}

.callback-section-wrapper {
    margin: spacing(2.5) 0;
}

.request-another-callback-content-wrapper {
    padding: 0 spacing(2.5);
}

.copy-case-button {
    width: spacing(22);
}

.ui.accordion.admin-next-steps {
    display: flex;
    flex-direction: column;
    margin-top: spacing(3);
    border: 1px solid $colour-primary;

    .title {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        height: spacing(5.25);
        font-size: 16px;
        font-weight: 600;
        background-color: $colour-secondary;

        i.icon {
            margin-left: 5px;
        }

        i.icon.info {
            border-radius: 50%;
            border: solid 1px $black;
            cursor: pointer;
        }
    }

    .ui.form {
        padding: spacing(2.5) spacing(10) spacing(5);

        label {
            color: #707171;
        }

        .field {
            padding-right: spacing(8);

            .text {
                width: 95%;
            }

            .ui.label {
                border: unset;
                line-height: 18px;
                padding: 4px 10px;
                border-radius: spacing(2);
                box-shadow: unset;
                background-color: $gray-background;
                font-size: 14px;
                font-weight: 400;
                color: $black;
                vertical-align: initial;
            }
        }
    }

    .question-group-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;

        .left-column {
            flex-basis: 50%;
        }

        .right-column {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-width: 45%;
            div {
                margin-bottom: spacing(1);
            }
        }

        .selection.dropdown {
            a {
                max-width: 100%;
            }
        }

        textarea {
            height: 42px;
        }

        .custom-select-container {
            padding-right: 64px;
        }
    }

    .admin-next-steps-answer-container {
        margin: spacing(2.5) spacing(5);

        .ui.segment.admin-next-steps-answer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #e5e5e5;
            min-height: 56px;
            border-radius: 6px;
        }
    }
}

.ui.segment.admin-next-steps-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-block-start: 0.5em;
    padding-block-end: 0.5em;

    .question {
        flex: 2;
        padding-right: spacing(2);
    }

    .answer {
        flex: 1;
        max-width: 33.3%;
        font-weight: bold;
        overflow-wrap: break-word;
    }

    .no-answer {
        flex: 1;
        max-width: 33.3%;
        font-weight: normal;
        overflow-wrap: break-word;
    }

    .child-question-list {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-left: 3em;
    }
}

.additional-administrative-details-review {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    font-weight: 600;
    border: solid;
    border-color: #e5e5e5;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
    border-width: thin;
}

.additional-administrative-details-view {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    font-weight: 600;
    border: solid;
    border-color: #e5e5e5;
    border-radius: 5px;
    margin-top: 10px;
    border-width: thin;
}

.additional-administrative-details-info-icon {
    flex-shrink: 0;
    margin: 5px;
}

.additional-administrative-details-content {
    flex-grow: 1;
    margin: 10px;
}

.additional-administrative-details-content-item {
    margin-top: 10px;
}
