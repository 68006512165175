@import "../../../../../../scss/mixins";
@import "../../../../../../scss/variables";

.progress-status {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: spacing(3);
    padding: 0 0 0 10px;
    text-transform: capitalize;
}
.completed-block {
    width: 20px;
    height: 20px;
    float: left;
}
.uncompleted-block {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0 0 0 6px;
    background-color: $mid-grey;
    float: left;
    color: $white;
}
